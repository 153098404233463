import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { selectError } from '@paradigm/store/error';

import {
  acceptedAgreement,
  getUserInfoRequestAction,
  selectIsAuthenticated,
  selectHasUnacceptedAgreement,
} from '@paradigm/store/auth';
import { PreferencesProvider } from '@paradigm/features/src/preferences/context';
import { acceptAgreementApi, acceptNewAgreementApi } from '@paradigm/api/auth';
import NoInternet from '#/components/no-internet';
import Sidebar from '#/components/sidebar/Sidebar';
import PopupError from '#/components/PopupError';
import RestrictedIp from '#/components/restricted-ip/restricted-ip';
import AcceptAgreement from '#/components/accept-agreement/AcceptAgreement';

import { selectIsAppOffline } from '#/redux/selectors/app';

import ParadigmLogoVertical from '@paradigm/design-system/src/assets/ParadigmLogoVertical';
import NewAcceptAgreement from '#/components/accept-agreement/NewAcceptAgreement';

import DefaultAccountsProvider from '@paradigm/features/src/preferences/default-accounts/context';
import AccountsProvider from '@paradigm/features/src/unified-rfqs/contexts/accounts';
import DefaultAccountModalProvider from '@paradigm/features/src/preferences/default-accounts/modal-context';

const LayoutContainer = ({ children }) => {
  const dispatch = useDispatch();

  const error = useSelector(selectError);
  const isErrorPopupActive = error.length > 0;
  const ipWarningActive = useSelector(
    (state) => state.auth.restrictedIpWarning,
  );
  const isAuthenticated = useSelector(selectIsAuthenticated);

  const isAppOffline = useSelector(selectIsAppOffline);
  const pathname = useSelector((state) => state.router.location.pathname);
  const showAgreementOverlay = useSelector(
    (state) => state.auth.agreementOverlay,
  );

  const hasUnacceptedAgreement = useSelector(selectHasUnacceptedAgreement);

  if (showAgreementOverlay) {
    return (
      <div className="layout__body">
        <div className="layout__gradient flex-column align-items-center">
          <div className="layout__logo flex-column align-items-center flex-shrink-0">
            <ParadigmLogoVertical />
          </div>
          <AcceptAgreement
            onAccept={(agreement) =>
              acceptAgreementApi(agreement).then(() =>
                dispatch(acceptedAgreement()),
              )
            }
          />
        </div>
      </div>
    );
  }
  if (isAuthenticated && hasUnacceptedAgreement) {
    return (
      <div className="layout__body">
        <div className="layout__gradient flex-column align-items-center">
          <div className="layout__logo flex-column align-items-center flex-shrink-0">
            <ParadigmLogoVertical />
          </div>
          <NewAcceptAgreement
            onAccept={(agreement) =>
              acceptNewAgreementApi(agreement).then(() => {
                dispatch(getUserInfoRequestAction());
              })
            }
          />
        </div>
      </div>
    );
  }

  return (
    <div className="layout__body">
      {isErrorPopupActive && <PopupError />}
      {ipWarningActive && <RestrictedIp />}
      {isAuthenticated ? (
        <AccountsProvider>
          <PreferencesProvider>
            <DefaultAccountsProvider>
              <DefaultAccountModalProvider>
                <div
                  className={classNames('d-flex h-100 main-container', {
                    blur: isAppOffline,
                  })}
                >
                  <Sidebar location={pathname} />
                  {children}
                </div>
              </DefaultAccountModalProvider>
            </DefaultAccountsProvider>
          </PreferencesProvider>

          {isAppOffline && <NoInternet />}
        </AccountsProvider>
      ) : (
        <div className="d-flex flex-column align-items-center w-100 layout__gradient">
          <div className="layout__logo d-flex flex-column align-items-center flex-shrink-0">
            <ParadigmLogoVertical />
          </div>
          {children}
        </div>
      )}
    </div>
  );
};

LayoutContainer.propTypes = {
  children: PropTypes.node,
};

export default LayoutContainer;
