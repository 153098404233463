import { PropsWithChildren, useEffect, useMemo, useReducer } from 'react';
import { Maybe } from '@metamask/providers/dist/utils';
import capitalize from 'lodash/capitalize';
import createSafeContext from '@paradigm/utils/createSafeContext';
import { SelectOption } from '@paradigm/design-system/src/components/Select';
import { useAccounts } from '#/unified-rfqs/contexts/accounts';
import {
  DefaultAccountsRecord,
  VenueCode,
  isEmptyAccount,
  isValidVenue,
} from '#/preferences/default-accounts/types';
import { useProductsStore } from '#/products/store';
import { getAccountValue } from '#/preferences/default-accounts/store';
import { Product } from '#/products/types';
import { useDefaultAccountsFeatureFlag } from '#/feature-flags/context';
import usePersistedAccounts from '#/preferences/default-accounts/usePersistedAccounts';
import useAccountsMigration from '#/preferences/default-accounts/migration/useAccountsMigration';
import {
  reducer,
  initialize,
  setVenue,
  setSearchValue,
  setAllProductsDefault,
  setProductDefault,
  INITIAL_STATE_STORE,
  State,
} from './store';

interface DefaultAccountsActions {
  setVenue: (venue: VenueCode) => void;
  venueHasEmptyProduct: (venue: VenueCode) => boolean;
  setSearchValue: (searchValue: string) => void;
  setAllProductsDefault: (venue: VenueCode, accountName: string) => void;
  setProductDefault: (
    venue: VenueCode,
    productCode: string,
    accountName: string,
  ) => void;
  getDefaultAccount: (
    venue: string,
    productCode: Maybe<string>,
  ) => string | null;
}

interface VenueProduct {
  label: string;
  productCode: string;
}

interface DefaultAccountsState extends State {
  venueProducts: VenueProduct[];
  venueAccountsOptions: SelectOption[];
  isLoadingAccounts: boolean;
}

const [useDefaultAccountsState, DefaultAccountsProviderStateCtx] =
  createSafeContext<DefaultAccountsState>('DefaultAccountsState');
const [useDefaultAccountsActions, DefaultAccountsProviderActionsCtx] =
  createSafeContext<DefaultAccountsActions>('DefaultAccountsActions');
const [useDefaultAccountsView, DefaultAccountsProviderViewCtx] =
  createSafeContext<DefaultAccountsRecord>('DefaultAccountsView');

export {
  useDefaultAccountsState,
  useDefaultAccountsActions,
  useDefaultAccountsView,
};
export default function DefaultAccountsProvider({
  children,
}: PropsWithChildren) {
  const persistedStore = usePersistedAccounts();
  const [storeState, dispatch] = useAccountsStore();
  const { activeVenue } = storeState;
  const accountsView = useAccounts();
  const getActiveProducts = useProductsStore((s) => s.getActiveProducts);
  const isDefaultAccountEnabled = useDefaultAccountsFeatureFlag();
  const venueProducts = useMemo(
    () => getActiveProducts({ venue: activeVenue }) as Product[],
    [activeVenue, getActiveProducts],
  );

  const venueAccountsOptions = useMemo(
    () =>
      accountsView.selectByVenue(activeVenue).map((account) => ({
        value: account.name,
        label: toDisplayLabel(account.name),
      })),
    [accountsView, activeVenue],
  );

  const state = useMemo(
    () => ({
      ...storeState,
      venueProducts: venueProducts
        .map((product) => ({
          label: getProductLabel(product),
          productCode: product.code,
        }))
        .filter(({ label }) => {
          return label
            .toLowerCase()
            .includes(storeState.searchValue.toLowerCase());
        }),
      isLoadingAccounts: accountsView.isLoading,
      venueAccountsOptions,
    }),
    [accountsView.isLoading, storeState, venueAccountsOptions, venueProducts],
  );

  const view = useMemo(
    () => storeState.defaultAccounts,
    [storeState.defaultAccounts],
  );

  const actions: DefaultAccountsActions = useMemo(
    () => ({
      setVenue: (venue) => dispatch(setVenue(venue)),
      venueHasEmptyProduct: (venue: VenueCode) => {
        const venueAccounts = persistedStore.get()[venue];
        if (!isEmptyAccount(venueAccounts.all)) return false;

        return getActiveProducts({ venue }).some((product) =>
          isEmptyAccount(venueAccounts[product.code]),
        );
      },
      setSearchValue: (searchValue) => dispatch(setSearchValue(searchValue)),
      setAllProductsDefault: (venueCode, accountName) => {
        persistedStore.setVenueDefault(venueCode, accountName);
        dispatch(
          setAllProductsDefault({
            venueCode,
            accountName,
          }),
        );
      },
      setProductDefault: (venueCode, productCode, accountName) => {
        persistedStore.setProductDefault(venueCode, productCode, accountName);

        dispatch(
          setProductDefault({
            venueCode,
            productCode,
            accountName,
          }),
        );
      },
      getDefaultAccount: (venue, productCode) => {
        if (!isValidVenue(venue)) return null;
        const venueAccounts = persistedStore.get()[venue];
        let account = venueAccounts.all;
        if (isDefaultAccountEnabled && productCode != null) {
          account = venueAccounts[productCode] ?? venueAccounts.all;
        }
        return getAccountValue(account);
      },
    }),
    [dispatch, getActiveProducts, isDefaultAccountEnabled, persistedStore],
  );

  return (
    <DefaultAccountsProviderStateCtx value={state}>
      <DefaultAccountsProviderActionsCtx value={actions}>
        <DefaultAccountsProviderViewCtx value={view}>
          {children}
        </DefaultAccountsProviderViewCtx>
      </DefaultAccountsProviderActionsCtx>
    </DefaultAccountsProviderStateCtx>
  );
}

/**
 * Initialize default accounts state with values from user preferences API
 * and old legacy accounts apis. Compute final value and hydrate user preferences API
 * @returns
 */
function useAccountsStore() {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE_STORE);
  const persistedAccounts = usePersistedAccounts();

  const { status } = useAccountsMigration();

  useEffect(() => {
    if (state.initialized || status !== 'complete') return;

    // Update preferences after migrating legacy api accounts
    dispatch(initialize(persistedAccounts.get()));
  }, [persistedAccounts, state.initialized, status]);

  return [state, dispatch] as const;
}

const MAX_DISPLAY_CHAR = 18;
const toDisplayLabel = (label: string) => {
  if (label.length <= MAX_DISPLAY_CHAR) return label;

  return `${label.slice(0, 7)}...${label.slice(-7)}`;
};

const getProductLabel = ({ currency, kind, marginType, code }: Product) => {
  return `${currency} ${capitalize(kind)} - ${capitalize(
    marginType,
  )} (${code})`;
};
