import * as PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { required, email } from 'redux-form-validators';
import { UserForgotPasswordApi } from '@paradigm/api/auth';
import ConfirmModal from '#/utils/components/confirm-modal';

import { renderInputField } from '#/utils/renderFormFields';
import { renderError } from '#/utils/renderErrors';
import BackIcon from '@paradigm/design-system/src/assets/BackIcon';

const propNamespace = 'forgotPassword';

class ForgotPassword extends PureComponent {
  state = {
    success: '',
    error: '',
  };

  submit = (data) =>
    UserForgotPasswordApi(data)
      .then(() =>
        this.setState({
          error: '',
          success: 'Password reset email sent, please check your email.',
        }),
      )
      .catch(() =>
        this.setState({
          error: 'Something went wrong, please try again.',
          success: '',
        }),
      );

  resetMessage = () => this.setState({ success: '' });
  redirect = () => this.props.history.push('/');

  render() {
    const {
      [propNamespace]: { handleSubmit, submitting },
    } = this.props;
    const { success, error } = this.state;
    return (
      <div className="flex-column flex-center layout__container sign-up">
        <ConfirmModal
          isOpen={Boolean(success)}
          toggle={this.resetMessage}
          message={success}
          onConfirm={this.redirect}
          confirmText="OK"
        />
        <div className="row no-gutters align-items-center mb-4">
          <div className="mr-3">
            <Link to="/" role="link" style={{ color: 'white' }}>
              <BackIcon />
            </Link>
          </div>
          <div className="d-flex align-items-center">
            <h2 className="sign-up__title">Forgot password</h2>
          </div>
        </div>
        <form onSubmit={handleSubmit(this.submit)}>
          {renderError(error)}
          <div className="form-group">
            <Field
              component={renderInputField}
              type="text"
              name="email"
              label="Email Address"
              className="form-control"
              placeholder="Enter Email"
              disabled={submitting}
              validate={[required(), email()]}
            />
            <p className="py-2 light-font" style={{ fontSize: 12 }}>
              You will receive an email with a link to reset your password.
            </p>
            <div className="d-flex justify-content-end">
              <button
                type="submit"
                className="btn primary-button"
                disabled={submitting}
              >
                {!submitting ? 'Reset' : 'Resetting...'}
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

ForgotPassword.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
};

const ForgotPasswordDefault = reduxForm({
  form: 'forgotPassword',
  propNamespace,
})(ForgotPassword);

export default ForgotPasswordDefault;
