const protocolSuffix = ENVIRONMENT === 'production' ? '' : `-${ENVIRONMENT}`;
export const DESKTOP_PROTOCOL = `paradigm${protocolSuffix}`;
export const PWA_PROMPT_SOFT_KEY = 'pwa-prompt-soft-dismiss';
export const PWA_PROMPT_PERMANENT_KEY = 'pwa-prompt-permanent-dismiss';

export const FEATURE_UM_UI_OB_V1_API = 'feature_um_ui_ob_v1_api';
export const FEATURE_UM_LEG_PRICING_FALLBACK =
  'feature_um_leg_pricing_fallback';
export const FEATURE_UM_RFQ_HIDDEN_LIMIT_ORDER =
  'feature_um_rfq_hidden_limit_order';

export const ELECTRON_DEPRECATION_PROMPT_KEY =
  'electron-deprecation-prompt-dismiss';
