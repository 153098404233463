import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Reducer } from 'redux';
import {
  DefaultAccountsRecord,
  INITIAL_DEFAULT_ACCOUNTS,
  NO_DEFAULT_SELECT_OPTION,
  VenueCode,
} from '#/preferences/default-accounts/types';

export interface State {
  activeVenue: VenueCode;
  initialized: boolean;
  searchValue: string;
  defaultAccounts: DefaultAccountsRecord;
}

export const INITIAL_STATE_STORE: State = {
  activeVenue: 'DBT',
  initialized: false,
  searchValue: '',
  defaultAccounts: INITIAL_DEFAULT_ACCOUNTS,
};

type SetAllProductsAction = {
  venueCode: VenueCode;
  accountName: string;
};
type SetProductAction = {
  venueCode: VenueCode;
  productCode: string;
  accountName: string;
};

export const getAccountValue = (account: string | null) =>
  account === NO_DEFAULT_SELECT_OPTION.value ? null : account;

const slice = createSlice({
  name: 'default-accounts',
  initialState: INITIAL_STATE_STORE,
  reducers: {
    initialize: (state, action: PayloadAction<State['defaultAccounts']>) => {
      state.defaultAccounts = action.payload;
      state.initialized = true;
    },
    setVenue: (state, action: PayloadAction<State['activeVenue']>) => {
      state.activeVenue = action.payload;
    },
    setSearchValue: (state, action: PayloadAction<State['searchValue']>) => {
      state.searchValue = action.payload;
    },
    setAllProductsDefault: (
      state,
      action: PayloadAction<SetAllProductsAction>,
    ) => {
      const { venueCode, accountName } = action.payload;
      state.defaultAccounts[venueCode].all = accountName;
    },
    setProductDefault: (state, action: PayloadAction<SetProductAction>) => {
      const { venueCode, productCode, accountName } = action.payload;
      state.defaultAccounts[venueCode][productCode] = accountName;
    },
  },
});

export const { reducer } = slice as { reducer: Reducer<State> };
export const {
  initialize,
  setVenue,
  setSearchValue,
  setAllProductsDefault,
  setProductDefault,
} = slice.actions;
